import React from 'react';

const Dashboard = React.lazy(() => import('./dashboard'));
const KPIs = React.lazy(() => import('./kpis'));
const Schemes = React.lazy(() => import('./schemes'));

export const performanceEvaluationConfig = [
    {
        path: '/performance-evaluation/dashboard',
        element: <Dashboard />,
    },
    {
        path: '/performance-evaluation/kpis',
        element: <KPIs />,
    },
    {
        path: '/performance-evaluation/schemes/',
        element: <Schemes />,
    },
];
