// ForOAuth2
import { getUserFromOAuth2 } from './helper/AuthHelper';
import { useOAuth, useOAuthActions } from '../services/auth/oauth2/OAuthProvider';

export const useAuthUser = () => {
    const { user, isAuthenticated, isLoading } = useOAuth();
    return {
        isLoading,
        isAuthenticated,
        user: getUserFromOAuth2(user),
    };
};

export const useAuthMethod = () => {
    const { signInUser, signUpUser, logout, forgetPasswordUser, resetPasswordUser } = useOAuthActions();

    return {
        signInUser,
        logout,
        signUpUser,
        forgetPasswordUser,
        resetPasswordUser,
    };
};
