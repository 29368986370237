import React from 'react';
import { AppSuspense } from '../../index';
import { anonymousStructure, authorizedStructure, unAuthorizedStructure } from '../../../pages';
import AppFooter from '../AppLayout/components/AppFooter';
import AppErrorBoundary from '../AppErrorBoundary';
import generateRoutes from '../../utility/RouteGenerator';
import { useAuthUser } from '../../utility/AuthHooks';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import AppContentViewWrapper from './AppContentViewWrapper';

const AppContentView = ({ sxStyle }) => {
    const { user, isAuthenticated } = useAuthUser();
    return (
        <AppContentViewWrapper className='app-content-view'>
            <Box
                sx={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    p: { xs: 5, md: 7.5, xl: 12.5 },
                    ...sxStyle,
                }}
                className='app-content'
            >
                <AppSuspense>
                    <AppErrorBoundary>
                        {generateRoutes({
                            isAuthenticated: isAuthenticated,
                            userRole: user?.role,
                            unAuthorizedStructure,
                            authorizedStructure,
                            anonymousStructure,
                        })}
                    </AppErrorBoundary>
                </AppSuspense>
            </Box>
            <AppFooter />
        </AppContentViewWrapper>
    );
};

export default AppContentView;

AppContentView.propTypes = {
    sxStyle: PropTypes.object,
};
