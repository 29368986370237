import React from 'react';
import { Box } from '@mui/material';

const AppLogo = () => {
    return (
        <Box
            sx={{
                height: { xs: 56, sm: 70 },
                padding: 2.5,
                display: 'flex',
                flexDirection: 'row',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
                '& svg': {
                    height: { xs: 40, sm: 45 },
                },
                '& .logo': {
                    height: { xl: 50, xs: 45, sm: 50 },
                },
            }}
            className='app-logo'
        >
            <Box
                sx={{
                    mt: 1,
                    display: { xs: 'none', md: 'block' },
                    '& svg': {
                        height: { xs: 25, sm: 30 },
                    },
                    '& .logo': {
                        maxWidth: { xl: '100%', sm: '100%' },
                    },
                }}
            >
                <img className='logo' src={'/static/assets/images/logos/total_integrity_large.svg'} alt='totalintegrity' title='totalintegrity' />
            </Box>
        </Box>
    );
};

export default AppLogo;
