import React from 'react';

const Assessment = React.lazy(() => import('./assessment'));
const ListAssessment = React.lazy(() => import('./listAssessment'));

export const integrityAssessmentConfig = [
    {
        path: '/integrity-assessesment/assessment',
        element: <Assessment />,
    },
    {
        path: '/integrity-assessesment/list/assessment',
        element: <ListAssessment />,
    },
];
