import React from 'react';
import { Navigate } from 'react-router-dom';
import { initialUrl } from 'shared/constants/AppConst';

import { authRouteConfig } from './auth';
import Error403 from './errorPages/Error403';
import { errorPagesConfigs } from './errorPages';
import { dashBoardConfigs } from './dashboards';

import { userPagesConfig } from './userPages';
import { riskManagementConfig } from './riskManagement';
import { informationManagementConfig } from './dataManagement';
import { integrityAssessmentConfig } from './integrityAssessment';
import { activityManagementConfig } from './activityManagement';
import { performanceEvaluationConfig } from './PerformanceEvaluation';
import { corrosionEvaluationConfig } from './corrosionEvaluation';
import { demageMechanismConfig } from './demageMechanism';
import { gisConfig } from './gis';
import { regulatoryComplianceConfig } from './regulatoryCompliance';
import { accountPagesConfigs } from './account';
import { administrationConfig } from './administration';

const authorizedStructure = {
    fallbackPath: '/signin',
    unAuthorizedComponent: <Error403 />,
    routes: [
        ...accountPagesConfigs,
        ...dashBoardConfigs,
        ...userPagesConfig,
        ...riskManagementConfig,
        ...informationManagementConfig,
        ...integrityAssessmentConfig,
        ...activityManagementConfig,
        ...performanceEvaluationConfig,
        ...corrosionEvaluationConfig,
        ...demageMechanismConfig,
        ...gisConfig,
        ...regulatoryComplianceConfig,
        ...administrationConfig,
    ],
};

const unAuthorizedStructure = {
    fallbackPath: initialUrl,
    routes: authRouteConfig,
};

const anonymousStructure = {
    routes: errorPagesConfigs.concat([
        {
            path: '/',
            element: <Navigate to={initialUrl} />,
        },
        {
            path: '*',
            element: <Navigate to='/error-pages/error-404' />,
        },
    ]),
};

export { authorizedStructure, unAuthorizedStructure, anonymousStructure };
