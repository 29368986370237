import React from 'react';

const ExternalCorrosion = React.lazy(() => import('./externalCorrosion'));
const InternalCorrosion = React.lazy(() => import('./internalCorrosion'));

export const corrosionEvaluationConfig = [
    {
        path: '/corrosion-evaluation/internal',
        element: <InternalCorrosion />,
    },
    {
        path: '/corrosion-evaluation/external',
        element: <ExternalCorrosion />,
    },
];
