import { SELECTED_OPTION_FILTER, TYPE_FILTER } from 'shared/constants/ActionTypes';

const INIT_STATE = {
    option: null,
    typeFilter: null,
};

const filterReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SELECTED_OPTION_FILTER: {
            return {
                ...state,
                option: action.payload,
            };
        }
        case TYPE_FILTER: {
            return {
                ...state,
                typeFilter: action.payload,
            };
        }
        default:
            return state;
    }
};
export default filterReducer;
