import React from 'react';

const Maps = React.lazy(() => import('./maps'));

export const gisConfig = [
    {
        path: '/gis/maps',
        element: <Maps />,
    },
];
