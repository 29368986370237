import axiosInstance from '../../auth/oauth2';

export const updateProfile = async (id, data) => {
    try {
        let response = await axiosInstance.patch(`/api/accounts/user/update/${id}/`, data);
        return {
            success: true,
            data: response.data,
        };
    } catch (error) {
        return {
            success: false,
            data: error.response ? error.response : error,
        };
    }
};

export const changePasswordUser = async (data) => {
    try {
        let response = await axiosInstance.put(`/api/accounts/user/change-password/`, data);
        return {
            success: true,
            data: response.data,
        };
    } catch (error) {
        return {
            success: false,
            data: error.response ? error.response : error,
        };
    }
};
