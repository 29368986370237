import saMessages from '../locales/es_ES.json';
import { esES } from '@mui/material/locale';

const saLang = {
    messages: {
        ...saMessages,
    },
    muiLocale: esES,
    locale: 'es',
};
export default saLang;
