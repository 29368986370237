import React from 'react';

const NOM004 = React.lazy(() => import('./nom004'));
const NOM007 = React.lazy(() => import('./nom007'));
const NOM009 = React.lazy(() => import('./nom009'));
const NOM020 = React.lazy(() => import('./nom020'));

export const regulatoryComplianceConfig = [
    {
        path: '/regulatory-compliance/nom-004',
        element: <NOM004 />,
    },
    {
        path: '/regulatory-compliance/nom-007',
        element: <NOM007 />,
    },
    {
        path: '/regulatory-compliance/nom-009',
        element: <NOM009 />,
    },
    {
        path: '/regulatory-compliance/nom-020',
        element: <NOM020 />,
    },
];
