import React from 'react';

const OrderList = React.lazy(() => import('./orders/List'));

export const activityManagementConfig = [
    {
        path: '/activity-management/orders/list',
        element: <OrderList />,
    },
];
