import axios from 'axios';
import LocalStorageService from './LocalStoageService';
import https from 'https';
// import router from "./router/router";

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    httpsAgent: new https.Agent({
        rejectUnauthorized: false,
    }),
});

// LocalstorageService
const localStorageService = LocalStorageService.getService();

// Add a request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorageService.getAccessToken();
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
            localStorage.setItem('token', token);
        } else {
            delete axiosInstance.defaults.headers.common['Authorization'];
            localStorage.removeItem('token');
        }
        // config.headers['Content-Type'] = 'application/json';
        return config;
    },
    (error) => {
        Promise.reject(error);
    },
);
//Add a response interceptor

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && originalRequest.url === `${process.env.REACT_APP_BASE_URL}/o/token/`) {
            //    router.push('/login');
            localStorageService.clearToken();
            window.location.href = '/signin';
            return Promise.reject(error);
        }
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = localStorageService.getRefreshToken();
            return axiosInstance
                .post('/o/token/', {
                    client_id: process.env.REACT_APP_CLIENT_ID,
                    client_secret: process.env.REACT_APP_CLIENT_SECRET,
                    refresh_token: refreshToken,
                })
                .then((res) => {
                    if (res.status === 201) {
                        localStorageService.setToken(res.data);
                        axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + localStorageService.getAccessToken();
                        return axiosInstance(originalRequest);
                    }
                });
        }
        return Promise.reject(error);
    },
);

export default axiosInstance;
