import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Settings from './Setting';
import Common from './Common';
import Dashboard from './Dashboard';
import Filter from './Filter';

const reducers = (history) =>
    combineReducers({
        router: connectRouter(history),
        settings: Settings,
        dashboard: Dashboard,
        common: Common,
        filter: Filter,
    });
export default reducers;
