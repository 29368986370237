import React from 'react';
import { RoutePermittedRole } from 'shared/constants/AppConst';

const Metrics = React.lazy(() => import('./Metrics'));

export const dashBoardConfigs = [
    {
        permittedRole: RoutePermittedRole.user,
        path: '/dashboards/metrics',
        element: <Metrics />,
    },
];
