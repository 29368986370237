import React from 'react';

const PipelineRiskAnalysis = React.lazy(() => import('./pipelineRiskAnalysis'));
const RiskBasedInspection = React.lazy(() => import('./riskBasedInspection'));
const CriticalityAnalysis = React.lazy(() => import('./criticalityAnalysis'));

export const riskManagementConfig = [
    {
        path: '/risk-management/pipeline-risk-analysis',
        element: <PipelineRiskAnalysis />,
    },
    {
        path: '/risk-management/risk-based-inspection',
        element: <RiskBasedInspection />,
    },
    {
        path: '/risk-management/criticality-analysis',
        element: <CriticalityAnalysis />,
    },
];
