import React from 'react';

const AssetHierarchy = React.lazy(() => import('./assetHierarchy'));
const Consult = React.lazy(() => import('./consult'));
const ListHierarchy = React.lazy(() => import('./listHierarchy'));

export const informationManagementConfig = [
    {
        path: '/information-management/asset-hierarchy',
        element: <AssetHierarchy />,
    },
    {
        path: '/information-management/consult',
        element: <Consult />,
    },
    {
        path: '/information-management/hierarchy/list',
        element: <ListHierarchy />,
    },
];
