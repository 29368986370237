import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from 'shared/constants/ActionTypes';
import axiosInstance from './index';
import LocalStorageService from './LocalStoageService';
import https from 'https';
import axios from 'axios';

const axiosInstance2 = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    httpsAgent: new https.Agent({
        rejectUnauthorized: false,
    }),
});

// LocalstorageService
const localStorageService = LocalStorageService.getService();

const OAuthContext = createContext();
const OAuthActionsContext = createContext();

export const useOAuth = () => useContext(OAuthContext);

export const useOAuthActions = () => useContext(OAuthActionsContext);

const OAuthProvider = ({ children }) => {
    const [userData, setUserData] = useState({
        user: null,
        isAuthenticated: false,
        isLoading: true,
    });

    const dispatch = useDispatch();

    useEffect(() => {
        const getAuthUser = () => {
            const token = localStorage.getItem('token');

            if (!token) {
                setUserData({
                    user: undefined,
                    isLoading: false,
                    isAuthenticated: false,
                });
                return;
            }
            // setAuthToken(token);
            axiosInstance
                .get('/api/accounts/user/profile/')
                .then(({ data }) =>
                    setUserData({
                        user: data,
                        isLoading: false,
                        isAuthenticated: true,
                    }),
                )
                .catch(() =>
                    setUserData({
                        user: undefined,
                        isLoading: false,
                        isAuthenticated: false,
                    }),
                );
        };

        getAuthUser();
    }, []);

    const signInUser = async ({ username, password }) => {
        dispatch({ type: FETCH_START });
        try {
            const form = new FormData();
            form.append('username', username);
            form.append('password', password);
            form.append('client_id', process.env.REACT_APP_CLIENT_ID);
            form.append('client_secret', process.env.REACT_APP_CLIENT_SECRET);
            form.append('grant_type', 'password');
            const { data } = await axiosInstance.post('/o/token/', form, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            localStorage.setItem('token', data.access_token);
            localStorageService.setToken(data);
            // setAuthToken(data.token);
            const res = await axiosInstance.get('/api/accounts/user/profile/');
            setUserData({
                user: res.data,
                isAuthenticated: true,
                isLoading: false,
            });
            dispatch({ type: FETCH_SUCCESS });
        } catch (error) {
            setUserData({
                ...userData,
                isAuthenticated: false,
                isLoading: false,
            });
            dispatch({
                type: FETCH_ERROR,
                payload: error?.response?.data?.error_description || 'Something went wrong',
            });
        }
    };

    const signUpUser = async ({ name, email, password }) => {
        dispatch({ type: FETCH_START });
        try {
            const { data } = await axiosInstance.post('users', {
                name,
                email,
                password,
            });
            localStorage.setItem('token', data.token);
            // setAuthToken(data.token);
            const res = await axiosInstance.get('/auth');
            setUserData({
                user: res.data,
                isAuthenticated: true,
                isLoading: false,
            });
            dispatch({ type: FETCH_SUCCESS });
        } catch (error) {
            setUserData({
                ...userData,
                isAuthenticated: false,
                isLoading: false,
            });
            console.log('error:', error.response.data.error);
            dispatch({
                type: FETCH_ERROR,
                payload: error?.response?.data?.error || 'Something went wrong',
            });
        }
    };

    const logout = async () => {
        localStorage.removeItem('token');
        // setAuthToken();
        setUserData({
            user: null,
            isLoading: false,
            isAuthenticated: false,
        });
    };

    const forgetPasswordUser = async ({ email }) => {
        dispatch({ type: FETCH_START });
        try {
            let result = await axiosInstance2.post('/api/accounts/request-reset-email/', { email });
            dispatch({ type: FETCH_SUCCESS });
            return result.data;
        } catch (error) {
            dispatch({
                type: FETCH_ERROR,
                payload: error?.response?.data?.error || 'Something went wrong',
            });
            return error?.response?.data;
        }
    };

    const resetPasswordUser = async ({ password, token, uidb64, pin_code }) => {
        dispatch({ type: FETCH_START });
        try {
            let result = await axiosInstance2.patch('/api/accounts/password-reset-complete/', { password, token, uidb64, pin_code });
            dispatch({ type: FETCH_SUCCESS });
            console.log('result ->', result);
            return result.data;
        } catch (error) {
            dispatch({
                type: FETCH_ERROR,
                payload: error?.response?.data?.error || 'Something went wrong',
            });
            console.log('error pass ->', error?.response?.data);
            let data = {
                message: error?.response?.data?.detail,
                success: false,
            };
            return error?.response?.data?.detail ? data : error?.response?.data;
        }
    };

    return (
        <OAuthContext.Provider
            value={{
                ...userData,
            }}
        >
            <OAuthActionsContext.Provider
                value={{
                    signUpUser,
                    signInUser,
                    logout,
                    setUserData,
                    forgetPasswordUser,
                    resetPasswordUser,
                }}
            >
                {children}
            </OAuthActionsContext.Provider>
        </OAuthContext.Provider>
    );
};
export default OAuthProvider;

OAuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
