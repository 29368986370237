import React from 'react';
import { toggleNavCollapsed } from '../../../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import AppScrollbar from '../../../AppScrollbar';
import MainSidebar from '../../components/MainSidebar';
import Hidden from '@mui/material/Hidden';
import Drawer from '@mui/material/Drawer';
import VerticalNav from '../../components/VerticalNav';
import StandardSidebarWrapper from './StandardSidebarWrapper';
import UserInfo from '../../components/UserInfo';
import { useLayoutContext } from '../../../../utility/AppContextProvider/LayoutContextProvider';
import { useSidebarContext } from '../../../../utility/AppContextProvider/SidebarContextProvider';

const AppSidebar = (props) => {
    const dispatch = useDispatch();
    const navCollapsed = useSelector(({ settings }) => settings.navCollapsed);
    const { footer, footerType } = useLayoutContext();

    const { sidebarTextColor } = useSidebarContext();

    const handleToggleDrawer = () => {
        dispatch(toggleNavCollapsed());
    };

    return (
        <>
            <Hidden xlUp>
                <Drawer
                    anchor={props.position}
                    open={navCollapsed}
                    onClose={() => handleToggleDrawer()}
                    classes={{
                        root: clsx(props.variant),
                        paper: clsx(props.variant),
                    }}
                    style={{ position: 'absolute' }}
                >
                    <StandardSidebarWrapper className='standard-sidebar'>
                        <MainSidebar>
                            <UserInfo color={sidebarTextColor} />
                            <AppScrollbar
                                sx={{
                                    py: 2,
                                    height: 'calc(100vh - 70px) !important',
                                }}
                                scrollToTop={false}
                            >
                                <VerticalNav />
                            </AppScrollbar>
                        </MainSidebar>
                    </StandardSidebarWrapper>
                </Drawer>
            </Hidden>
            <Hidden lgDown>
                <StandardSidebarWrapper className='standard-sidebar'>
                    <MainSidebar>
                        <UserInfo color={sidebarTextColor} />
                        <AppScrollbar
                            className={clsx({
                                'has-footer-fixed': footer && footerType === 'fixed',
                            })}
                            sx={{
                                py: 2,
                                height: 'calc(100vh - 141px) !important',
                                '&.has-footer-fixed': {
                                    height: 'calc(100vh - 188px) !important',
                                },
                            }}
                            scrollToTop={false}
                        >
                            <VerticalNav />
                        </AppScrollbar>
                    </MainSidebar>
                </StandardSidebarWrapper>
            </Hidden>
        </>
    );
};
export default AppSidebar;

AppSidebar.defaultProps = {
    variant: '',
    position: 'left',
};

AppSidebar.propTypes = {
    position: PropTypes.string,
    variant: PropTypes.string,
};
