import React from 'react';

const ListSite = React.lazy(() => import('./catalogs/site/ListSite'));
const ListPlant = React.lazy(() => import('./catalogs/plant/ListPlant'));
const ListSystem = React.lazy(() => import('./catalogs/system/ListSystem'));

export const administrationConfig = [
    {
        path: '/administration/catalogs/sites',
        element: <ListSite />,
    },
    {
        path: '/administration/catalogs/plants',
        element: <ListPlant />,
    },
    {
        path: '/administration/catalogs/systems',
        element: <ListSystem />,
    },
];
