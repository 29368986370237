import React from 'react';

const Evaluation = React.lazy(() => import('./evaluation'));

export const demageMechanismConfig = [
    {
        path: '/demage-mechanism/evaluation',
        element: <Evaluation />,
    },
];
