import React from 'react';
import { Provider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import AuthRoutes from '@crema/utility/AuthRoutes';
import AppContextProvider from '@crema/utility/AppContextProvider';
import AppThemeProvider from '@crema/utility/AppThemeProvider';
import AppStyleProvider from '@crema/utility/AppStyleProvider';
import AppLocaleProvider from '@crema/utility/AppLocaleProvider';
import AppLayout from '@crema/core/AppLayout';
import configureStore, { history } from 'redux/store';
// import FirebaseAuthProvider from './@crema/services/auth/firebase/FirebaseAuthProvider';
import OAuthProvider from './@crema/services/auth/oauth2/OAuthProvider';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const store = configureStore();
const queryClient = new QueryClient();

const App = () => (
    <AppContextProvider>
        <Provider store={store}>
            <AppThemeProvider>
                <AppStyleProvider>
                    <AppLocaleProvider>
                        <BrowserRouter history={history}>
                            <QueryClientProvider client={queryClient}>
                                <OAuthProvider>
                                    <AuthRoutes>
                                        <CssBaseline />
                                        <AppLayout />
                                    </AuthRoutes>
                                </OAuthProvider>
                            </QueryClientProvider>
                        </BrowserRouter>
                    </AppLocaleProvider>
                </AppStyleProvider>
            </AppThemeProvider>
        </Provider>
    </AppContextProvider>
);

export default App;
