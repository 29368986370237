const messageData = [
    {
        id: 201,
        image: '/static/assets/images/avatar/A19.jpg',
        message: 'Hey man! Whatsapp?',
        name: 'Angelina Joew',
    },
    {
        id: 202,
        image: '/static/assets/images/avatar/A15.jpg',
        message: 'I am fine, what about you?',
        name: 'John Matthew',
    },
    {
        id: 203,
        image: '/static/assets/images/avatar/A21.jpg',
        message: 'Call me when you are free!',
        name: 'George Bailey',
    },
    {
        id: 204,
        image: '/static/assets/images/avatar/A25.jpg',
        message: 'Send your contact details!',
        name: 'Maria Lee',
    },
    {
        id: 205,
        image: '/static/assets/images/avatar/A19.jpg',
        message: 'Hey man! Whatsapp?',
        name: 'Angelina Joew',
    },
    {
        id: 206,
        image: '/static/assets/images/avatar/A15.jpg',
        message: 'I am fine, what about you?',
        name: 'John Matthew',
    },
    {
        id: 207,
        image: '/static/assets/images/avatar/A21.jpg',
        message: 'Call me when you are free!',
        name: 'George Bailey',
    },
    {
        id: 208,
        image: '/static/assets/images/avatar/A25.jpg',
        message: 'Send your contact details!',
        name: 'Maria Lee',
    },
    {
        id: 209,
        image: '/static/assets/images/avatar/A19.jpg',
        message: 'Hey man! Whatsapp?',
        name: 'Angelina Joew',
    },
    {
        id: 210,
        image: '/static/assets/images/avatar/A15.jpg',
        message: 'I am fine, what about you?',
        name: 'John Matthew',
    },
    {
        id: 211,
        image: '/static/assets/images/avatar/A21.jpg',
        message: 'Call me when you are free!',
        name: 'George Bailey',
    },
    {
        id: 212,
        image: '/static/assets/images/avatar/A25.jpg',
        message: 'Send your contact details!',
        name: 'Maria Lee',
    },
];
export default messageData;
