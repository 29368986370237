import saMessages from '../locales/fr_FR.json';
import { frFR } from '@mui/material/locale';

const saLang = {
    messages: {
        ...saMessages,
    },
    muiLocale: frFR,
    locale: 'fr-FR',
};
export default saLang;
