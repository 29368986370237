import { authRole } from '../../../shared/constants/AppConst';

export const getUserFromAuth0 = (user) => {
    if (user)
        return {
            id: 1,
            uid: user.sub,
            displayName: user.name,
            email: user.email,
            photoURL: user.picture,
            role: authRole.user,
        };
    return user;
};

export const getUserFromFirebase = (user) => {
    if (user)
        return {
            id: 1,
            uid: user.uid,
            displayName: user.displayName ? user.displayName : 'Crema User',
            email: user.email,
            photoURL: user.photoURL ? user.photoURL : '/static/assets/images/avatar/A11.jpg',
            role: authRole.user,
        };
    return user;
};
export const getUserFromAWS = (user) => {
    if (user)
        return {
            id: 1,
            uid: user.username,
            displayName: user.attributes.name ? user.attributes.name : 'Crema User',
            email: user.attributes.email,
            photoURL: user.photoURL,
            role: authRole.user,
        };
    return user;
};

export const getUserFromJwtAuth = (user) => {
    if (user)
        return {
            id: 1,
            uid: user._id,
            displayName: user.name,
            email: user.email,
            photoURL: user.avatar,
            role: authRole.user,
        };
    return user;
};

export const getUserFromOAuth2 = (user) => {
    // console.log('user ->>', authRole);
    if (user)
        return {
            id: user.id,
            displayName: `${user.first_name} ${user.last_name}`,
            email: user.email,
            photoURL: user.photo,
            role: authRole?.user,
            username: user.username,
            first_name: user.first_name,
            last_name: user.last_name,
            position: user.position,
            company: user.company,
            photo: user.photo,
        };
    return user;
};
