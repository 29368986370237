import React from 'react';
import Button from '@mui/material/Button';
import IntlMessages from '../../utility/IntlMessages';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    // Typography,
} from '@mui/material';
import { Fonts } from '../../../shared/constants/AppEnums';
import { LoadingButton } from '@mui/lab';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});
const AppConfirmDialog = ({ open, onDeny, onConfirm, title, dialogTitle, loading }) => {
    return (
        <Dialog TransitionComponent={Transition} open={open} onClose={() => onDeny(false)}>
            <DialogTitle
                component='h4'
                variant='h4'
                sx={{
                    mb: 3,
                    fontWeight: Fonts.SEMI_BOLD,
                }}
            >
                {dialogTitle}
            </DialogTitle>
            <DialogContent sx={{ color: 'text.secondary', fontSize: 14 }} id='alert-dialog-description'>
                {title}
            </DialogContent>
            <DialogActions
                sx={{
                    pb: 5,
                    px: 6,
                }}
            >
                <LoadingButton
                    variant='outlined'
                    sx={{
                        fontWeight: Fonts.MEDIUM,
                    }}
                    onClick={onConfirm}
                    color='primary'
                    autoFocus
                    type='submit'
                    loading={loading}
                >
                    <IntlMessages id='common.yes' />
                </LoadingButton>
                <Button
                    variant='outlined'
                    sx={{
                        fontWeight: Fonts.MEDIUM,
                    }}
                    onClick={() => onDeny(false)}
                    color='secondary'
                >
                    <IntlMessages id='common.no' />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AppConfirmDialog;

AppConfirmDialog.propTypes = {
    dialogTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    open: PropTypes.bool.isRequired,
    onDeny: PropTypes.func.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onConfirm: PropTypes.func.isRequired,
    loading: PropTypes.bool,
};
