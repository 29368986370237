import React from 'react';
import { useAuthUser } from '@crema/utility/AuthHooks';
import { Formik } from 'formik';
import * as yup from 'yup';
import PersonalInfoForm from './PersonalInfoForm';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { updateProfile } from '../../../../@crema/services/apis/user';
import { useOAuthActions } from '../../../../@crema/services/auth/oauth2/OAuthProvider';

const validationSchema = yup.object({
    email: yup.string().email('Formato no válido').required('Campo requerido'),
});
const PersonalInfo = () => {
    const { user } = useAuthUser();
    const { setUserData } = useOAuthActions();

    // console.log('user -> ', user);

    return (
        <Box
            sx={{
                position: 'relative',
                maxWidth: 550,
            }}
        >
            <Formik
                validateOnBlur={true}
                initialValues={{
                    ...user,
                    photoURL: user.photo ? user.photo : '/static/assets/images/placeholder.jpg',
                }}
                validationSchema={validationSchema}
                onSubmit={async (data, { setSubmitting }) => {
                    setSubmitting(true);
                    console.log('data: ', data);
                    let formData = new FormData();
                    formData.append('first_name', data.first_name);
                    formData.append('last_name', data.last_name);
                    if (data.photoFile) {
                        formData.append('photo', data.photoFile, data.photo.photoFile);
                    }
                    formData.append('email', data.email);
                    let update = await updateProfile(user.id, formData);
                    if (update.success) {
                        setUserData({
                            user: update.data,
                            isAuthenticated: true,
                            isLoading: false,
                        });
                    }
                    // setSubmitting(false);
                }}
            >
                {({ values, setFieldValue, isSubmitting }) => {
                    return <PersonalInfoForm values={values} setFieldValue={setFieldValue} isSubmitting={isSubmitting} />;
                }}
            </Formik>
        </Box>
    );
};

export default PersonalInfo;

PersonalInfo.propTypes = {
    setFieldValue: PropTypes.func,
    values: PropTypes.string,
};
