export const ThemeStyle = {
    MODERN: 'modern',
    STANDARD: 'standard',
};
export const ThemeStyleRadius = {
    MODERN: 30,
    STANDARD: 16,
};
export const ThemeMode = {
    LIGHT: 'light',
    DARK: 'dark',
};
export const LayoutType = {
    FULL_WIDTH: 'full-width',
    BOXED: 'boxed',
    FRAMED: 'framed',
};
export const MenuStyle = {
    DEFAULT: 'default',
    STANDARD: 'standard',
    ROUNDED: 'rounded',
    ROUNDED_REVERSE: 'rounded-reverse',
    CURVED_MENU: 'curved-menu',
};
export const LayoutDirection = {
    RTL: 'rtl',
    LTR: 'ltr',
};
export const NavStyle = {
    DEFAULT: 'default',
    BIT_BUCKET: 'bit_bucket',
    STANDARD: 'standard',
    DRAWER: 'drawer',
    MINI: 'mini',
    MINI_SIDEBAR_TOGGLE: 'mini-sidebar-onToggleSidebar',
    HEADER_USER: 'user-header',
    HEADER_USER_MINI: 'user-mini-header',
    H_DEFAULT: 'h-default',
    HOR_HEADER_FIXED: 'hor-header-fixed',
    HOR_DARK_LAYOUT: 'hor-dark-layout',
};
export const FooterType = {
    FIXED: 'fixed',
    FLUID: 'fluid',
};
export const HeaderType = {
    FLUID: 'fluid',
    FIXED: 'fixed',
};
export const RouteTransition = {
    NONE: 'none',
    alpha: 'alpha',
    SLIDE_LEFT: 'slideLeft',
    SLIDE_RIGHT: 'slideRight',
    SLIDE_UP: 'slideUp',
    SLIDE_DOWN: 'slideDown',
};
export const Fonts = {
    LIGHT: '300',
    REGULAR: '400',
    MEDIUM: '500',
    SEMI_BOLD: '600',
    BOLD: '700',
};

export const AuthType = {
    FIREBASE: 'firebase',
    AWS_COGNITO: 'aws_cognito',
    AUTH0: 'auth0',
    JWT_AUTH: 'jwt_auth',
};
